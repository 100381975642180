import { configureStore } from '@reduxjs/toolkit';
import messagesReducer from './slice/messages';
import chatsReducer from './slice/chats';

export default configureStore({
  reducer: {
    messages: messagesReducer,
    chats: chatsReducer,
  },
})
